import React from 'react';

function Speech(props) {
   return (         
     <div style={{ fontFamily: 'B4C', fontSize: '1.5em'}}>
         N
     </div>
  )
}

export default Speech;