import React from 'react';
import Col from 'react-bootstrap/Col';
import { NavLink } from 'react-router-dom';

function simpleButton(props) {
   return (
   <Col className="simpleButton" as={NavLink} to={`${props.linktarget}`} >{props.name}</Col>
  )
}

export default simpleButton;