import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import '../Components.css';
import Arrows from './Arrows';

function ScrollableComponent(props) {

   return ( 
        <ScrollableAnchor id={`${props.current}`}>
            <div style={{ height: props.EnableScroll === true ? null : window.innerHeight}}> 
                { props.gotoprevious !== undefined ? 
                    <Arrows goto={props.gotoprevious} direction='E' helpText={props.tipup} /> : null } {/* scroll up */}                 
                {props.children}
                { props.gotonext !== undefined ? 
                    <Arrows goto={props.gotonext} direction='K' helpText={props.tipdown} /> : null } {/* scroll down */}
            </div>
        </ScrollableAnchor>     
  )
}

export default ScrollableComponent;