import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Components.css';

function DoubleColumnParagraph(props) {

   return (
       <Row style={{ 
          paddingBottom: props.buttonElement === true ? '2em' : '0.5em',
          paddingTop: props.buttonElement === true ? '2em' : '0.5em', 
          paddingLeft: '1em',
          paddingRight: '1em'
        }}> 
        { window.innerWidth > 400 ? <Col md={{ span: 1, offset: 2}} style={{ fontFamily: props.type === 'icon' ? '' : 'B4C', fontSize: props.type === 'icon' ? '1em' : '2em'  }} className='icons'>{props.image}</Col> : null 
        }
        <Col md={6} >{props.children}</Col>
        <Col md={1} >{props.speech}</Col>
      </Row> 
  )
}

export default DoubleColumnParagraph;