import React from 'react';
import Col from 'react-bootstrap/Col';

function ClickableTextArea(props) {
   return (     
            <Col as='a' href={props.href}> 
                  <Col><h3>{props.title}</h3></Col>
                  <Col><h4>{props.subtitle}</h4></Col>
                  <p style={{ padding: '1em', fontSize: '400'}}>{props.body}</p>
            </Col> 
  )
}

export default ClickableTextArea;