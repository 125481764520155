import React, { useState } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { SubscribeForm } from './subscribeForm';


function ContactButton(props) {
  const url = "https://blockchainforcommons.us10.list-manage.com/subscribe/post?u=9b6c2c83cffd71460381137d6&amp;id=09f7cf636e";

  const SubscribeBriefingList = () => (
    <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <SubscribeForm
        status={status}
        message={message}
        onValidated={formData => subscribe(formData)}
      />
    )}
    />
    )

  const [show, setShow] = useState(false)
  const handleFlip = () => setShow( show === true ? false : true );

  // should have popup message saying its send, well done, and clear comment, close show

  return (
    <div>
      <div className="contactButton" onClick={handleFlip}>{props.name}</div>
      { show === true ?
    <div> 
      {SubscribeBriefingList()}        
    </div>
      : 
      null }
    </div>
  )
}

export default ContactButton;