import React from 'react';
import Container from 'react-bootstrap/Container';
import HeaderSmall from '../Components/Header/header_small';
import Scrollable from '../Components/ScrollableComponent/Scrollable';
import ClickableTextArea from '../Components/TextArea/clickableTextArea';
import Col from 'react-bootstrap/Col';
import DoubleColumnParagraph from '../Components/DoubleColumnParagraph/DoubleColumnParagraph';

function Startuppage(props) {

   const bodyText = () => {
      return (
         <div>
            <table style={{ width: '100%'}}>
               <tr><th>location:</th><th>global developing markets</th></tr>
               <tr><th>sector:</th><th>agri-finance</th></tr>
               <tr><th>value model: </th><th>automated,instant,sponsorship model</th></tr>
               <tr><th>status:</th><th>pre-seed phase</th></tr>
            </table>
         </div>  
      )
   }
   const bodyText2 = () => {
      return (
         <div>
            <table style={{ width: '100%'}}>
               <tr><th>location:</th><th>global emerging/developing markets</th></tr>
               <tr><th>sector:</th><th>agri-finance</th></tr>
               <tr><th>value model: </th><th>instant, low-cost, low rate credit</th></tr>
               <tr><th>status:</th><th>growth phase</th></tr>
            </table>
         </div>  
      )
   }

   const bodyText3 = () => {
      return (
         <div>
         <table style={{ width: '100%'}}>
            <tr><th>location:</th><th>europe</th></tr>
            <tr><th>sector:</th><th>messaging services</th></tr>
            <tr><th>value model: </th><th>frictionless, pay by use</th></tr>
            <tr><th>status:</th><th><b>discontinued</b></th></tr>
         </table>
      </div>      
      )
   }

   return (
      <Container fluid="false"> 
      <Scrollable current={'1'} gotonext={'#2'} tipdown='chitta' EnableScroll={false}>                 
              <HeaderSmall />
              <DoubleColumnParagraph image='I'>
               <Col className="clickableTextArea">
                  <ClickableTextArea                         
                        href="https://asknila.in"
                        title='Nila supply chain and finance'                       
                        subtitle='Supply and finance protocols to elevate disadvantaged agri-chains.                        ' 
                        body={bodyText()}
                     />
               </Col>
              </DoubleColumnParagraph>
      </Scrollable>  
      <Scrollable current={'2'} gotoprevious={'#1'} gotonext={'#3'} tipdown='common' tipup='nila'>                 
              <DoubleColumnParagraph image='I'>
               <Col className="clickableTextArea">
                  <ClickableTextArea                         
                        href="https://docs.chitta.network"
                        title='chitta'                       
                        subtitle='Coordination of crop productions across farmers to allocate deep-tier finance and climate smart improvements' 
                        body={bodyText2()}
                     />
               </Col>
              </DoubleColumnParagraph>
      </Scrollable>  
      <Scrollable current={'3'} gotoprevious={'#2'} tipup='chitta'>
              <DoubleColumnParagraph image='I'>
                  <Col className="clickableTextArea"> 
                     <ClickableTextArea 
                           href="https://digitalcommon.org"
                           title='common' 
                           subtitle='Trust-less digital organizations as a service.' 
                           body={bodyText3()}
                        />
                  </Col>
              </DoubleColumnParagraph>
      </Scrollable>  
    </Container>
  )
}

export default Startuppage;