import React from 'react';
import Container from 'react-bootstrap/Container';
import Header from '../Components/Header/header';
import Scrollable from '../Components/ScrollableComponent/Scrollable';
import DoubleColumnParagraph from '../Components/DoubleColumnParagraph/DoubleColumnParagraph';
import SimpleButton from '../Components/Buttons/simpleButton';
import ContactButton from '../Components/Buttons/contactButton';
import ClickableTextArea from '../Components/TextArea/clickableTextArea';
import Col from 'react-bootstrap/Col';

function FrontPage(props) {
    const bodyText = () => {
        return (
          <div>
              <table style={{ width: '100%'}}>
                <tr><th>location:</th><th>global developing markets</th></tr>
                <tr><th>sector:</th><th>agri-finance</th></tr>
                <tr><th>value model: </th><th>automated,instant,sponsorship model</th></tr>
                <tr><th>status:</th><th>pre-seed phase</th></tr>
              </table>
          </div>  
        )
   }
   return (
      <Container fluid="false" >
        <Scrollable current={'1'} gotonext={'#2'} tipdown='chitta' EnableScroll={false}>            
                <Header />                
                <DoubleColumnParagraph image='I'>
                <Col className="clickableTextArea">
                    <ClickableTextArea                         
                          href="https://asknila.in"
                          title='Nila supply chain and finance'                       
                          subtitle='Supply and finance protocols to elevate disadvantaged agri-chains.                        ' 
                          body={bodyText()}
                      />
                </Col>
                </DoubleColumnParagraph>
        </Scrollable> 
        <Scrollable current={'2'} gotoprevious={'#1'}  gotonext={'#3'} tipdown='actions'>  
                <DoubleColumnParagraph image='A' >
                  <h2>"Digital financial infrastructures will be the backbone of post-deleveraged economies. Let us find out how your business can stay relevant in the future.”</h2>
                </DoubleColumnParagraph>
        </Scrollable>  
        <Scrollable gotoprevious={'#2'} current={'3'} gotonext={'#4'} tipup='menu' tipdown='contact'>
                <DoubleColumnParagraph image='B' buttonElement={true} ><ContactButton placeholder="Describe your question to us." inputBoxHeight='8em' name='contact: for general questions' /></DoubleColumnParagraph>
                <DoubleColumnParagraph image='C' buttonElement={true} ><SimpleButton name='play: deflation kit' linktarget='/advice#1' /></DoubleColumnParagraph>
                <DoubleColumnParagraph image='D' buttonElement={true} ><SimpleButton name='sign up: the friday morning briefing' linktarget='/briefings'  /></DoubleColumnParagraph>
        </Scrollable>
        <Scrollable gotoprevious={'#3'} current={'4'} tipup='actions'>
                <DoubleColumnParagraph image='H' buttonElement={true} ><h6>@b4commons</h6></DoubleColumnParagraph>
                <DoubleColumnParagraph image='G' buttonElement={true} ><h6>AtelierVA</h6></DoubleColumnParagraph>
                <DoubleColumnParagraph image='J' buttonElement={true} >
                  <h6>Amsterdam, the Netherlands</h6>
                  <h6>Chennai, India</h6>
                </DoubleColumnParagraph>
        </Scrollable>
      </Container>
  )
}

export default FrontPage;