import React from 'react';
import Menu from './_menu/_menu';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import './header.css';

function FrontPage(props) {

  const Scroll = () => {
    document.body.classList.add('stop-scrolling')
}
   return (
      <Col md={{span: 6, offset: 3}}>
        <Col className="headerTitle" as={Link} onClick={Scroll} to="/" >B4C</Col>
        <Menu />     
      </Col>
  )
}

export default FrontPage;