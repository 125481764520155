import React from 'react';
import Col from 'react-bootstrap/Col';
import Menu from './_menu/_menu';

function Header(props) {
   return (
      <Col md={{span: 6, offset: 3}} className="header">
        <Col className="headerTitle" >BLOCKCHAINFORCOMMONS</Col>
        <Menu />     
      </Col> 
  )
}

export default Header;