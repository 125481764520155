import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { NavLink } from 'react-router-dom';

function header_menu(props) {

    const Scroll = () => {
        document.body.classList.add('stop-scrolling')
    }

    return ( 
        <Col style={{ paddingBottom: window.innerHeight < 600 ? '0.5em' : '2em'}}>
            <Row>                 
                <Col as={NavLink} to="/startups" onClick={Scroll} className="fontgreen" activeClassName="selectedLink" >concepts</Col>
                <Col as={NavLink} to="/advice" onClick={Scroll} className="fontgreen" activeClassName="selectedLink" >advice</Col>
                <Col as={NavLink} to="/briefings" onClick={Scroll} className="fontgreen" activeClassName="selectedLink" >briefings</Col>
                <Col as={NavLink} to="/scoreboard" onClick={Scroll} className="fontgreen" activeClassName="selectedLink" >scoreboard</Col>
            </Row>
        </Col>
    )
}

export default header_menu;