import React from 'react';
import Container from 'react-bootstrap/Container';
import HeaderSmall from '../Components/Header/header_small';

function Scoreboardpage(props) {
   return (
      <Container fluid="false"> 
         <HeaderSmall /> 
      </Container>
  )
}

export default Scoreboardpage;