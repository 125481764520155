import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import HeaderSmall from '../Components/Header/header_small';
import Scrollable from '../Components/ScrollableComponent/Scrollable';
import Accordion from '../Components/Other/ArchiveAccordion';
import SubscribeButton from '../Components/Buttons/subscribeButton';
import DoubleColumnParagraph from '../Components/DoubleColumnParagraph/DoubleColumnParagraph';
import Speech from '../Components/Other/Speech';
import './Containers.css';
import { DataStore } from '@aws-amplify/datastore';
import { FridayNewsletter } from "../models";

async function GetNewsletters(setLetters) {
   const fridayNewsletters  = await DataStore.query(FridayNewsletter);
   setLetters(fridayNewsletters);
 }
function Briefingspage(props) {
   const [ ellipsis, setEllipsis] = useState(window.innerHeight / 4)
   const [ letters, setLetters ] = useState();

   useEffect(() => {
      GetNewsletters(setLetters)
      const subscription = DataStore.observe(FridayNewsletter).subscribe(msg => {
         GetNewsletters(setLetters);  
      });
       return () => subscription.unsubscribe();
   }, [])
  
   const EnableScroll = () => {
      document.body.classList.remove('stop-scrolling')
      setEllipsis(null)
    }

   const Scroll = () => {
      document.body.classList.add('stop-scrolling')
      setEllipsis(window.innerHeight / 4)
   }
   console.log('something', letters)
   return (
      <Container fluid="false" > 
      <Scrollable current={'1'} gotonext={'#2'} tipdown='archive' EnableScroll={ ellipsis === null ? true : false}>                 
              <HeaderSmall />
              <DoubleColumnParagraph image='last friday:' type='icon' odd={true} speech={<Speech />}>
                  <Col className="clickableTextArea">
                     <div style={{ padding: '1em'}} >
                        <h3>CHANGE IS COMMON</h3>
                        <p style={{ textOverflow: 'ellipsis', height: ellipsis, overflow: 'hidden', wordWrap: 'break-word' }}>
                        <br></br>
                        'Dear readers,  
                        <br></br><br></br>
                        I believe we are at a major turning point in our global societies. We might not notice it immediately, in the next year might have to go through a lot of devastating news events;  solvency issues, bankrupcies, price deflation, and a rolling series of lock-downs, but the way we see the world will basically stay the same. 
                        <br></br><br></br>
                        However in 10 years, we will look back at our last 10 years, and fail to see how we couldn’t notice the failing financial system we have constructed our welfare upon.  Surely,  the developed countries central banks printing presses can kick the can further down the road - again, but once the music will stop too in the US, Europe and Japan. All while emerging markets are already feeling the pain. 
                        <br></br><br></br>
                        My plan is to dedicate this newsletter to this transition. One in which a new monetary system will rise, based on new rules. 
                        <br></br><br></br>
                        Happy Weekend.' 
                        </p>
                     </div>
                     <Row style={{ paddingBottom: '1em'}}><Col><Col className="expandread" onClick={() => { ellipsis === null ? Scroll() : EnableScroll() }} >{ellipsis === null ? 'read less' : 'read more'}</Col></Col></Row>
                  </Col>               
               </DoubleColumnParagraph>             
              <DoubleColumnParagraph image='D' odd={true}>               
                  <SubscribeButton placeholder="Your email please"  inputBoxHeight='2em' name='send future newsletters automatically to my inbox' />
              </DoubleColumnParagraph>
      </Scrollable>  
      <Scrollable current={'2'} gotoprevious={'#1'} tipup='last friday' >  
            <Accordion date='friday april 21, 2020:' title='DIGITAL ASSETS AS A COMMODITY' body='some body text' />   
      </Scrollable>  
    </Container>
  )
}

export default Briefingspage;