import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import uuid from 'uuid/v4';
import { DataStore } from "@aws-amplify/datastore";
import { GeneralQuestion } from "../../models";

const MESSAGE_ID = uuid()

function ContactButton(props) {
  const [show, setShow] = useState(false)
  const handleFlip = () => setShow( show === true ? false : true );
  const [email, setEmail] = useState()
  const [name, setName] = useState()
  const [description, setDescription] = useState()
  
  async function onCreateMessage() {
    const result = await DataStore.save(
      new GeneralQuestion({
        id: MESSAGE_ID,
        name: name,
        email: email,
        description: description,
      })
    );    
    console.log(result)
  }

  return (
    <div>
      <div className="contactButton" onClick={handleFlip}>{props.name}</div>
      { show === true ?
    <div> 
       <input
          type="name"
          style={{ marginTop: '0.25em', border: 'none', width: '100%', padding: '0.5em'}}
          placeholder="name*"
          onChange={ (event) => setName(event.target.value)}
      />
      <input
          type="email"
          style={{ marginTop: '0.25em', border: 'none', width: '100%', padding: '0.5em'}}
          placeholder="e-mail address*"
          onChange={ (event) => setEmail(event.target.value)}
      />
      <textarea
          type="description"
          style={{ marginTop: '0.25em', border: 'none', width: '100%', padding: '0.5em'}}
          placeholder="Great, please describe your question here"
          onChange={ (event) => setDescription(event.target.value)}
      />
      <br />
      <Col md={2} className="simpleButton" onClick={onCreateMessage}>
          Submit
      </Col>     
    </div>
      : 
      null }
    </div>
  )
}

export default ContactButton;