import React from 'react';
import Container from 'react-bootstrap/Container';
import HeaderSmall from '../Components/Header/header_small';
import Scrollable from '../Components/ScrollableComponent/Scrollable';
import DoubleColumnParagraph from '../Components/DoubleColumnParagraph/DoubleColumnParagraph';
import texts from '../Util/texts.json';
import SimpleButton from '../Components/Buttons/simpleButton';

function AdvicePage(props) {
   return (
      <Container fluid="false"> 
        <Scrollable current={'1'} gotonext={'#2'} tipdown='play the game'>                 
                <HeaderSmall />
                <DoubleColumnParagraph image='C' odd={false}>
                  {texts.map((text, i) => {
                    return (         
                    <div>
                        <h6 key={i} style={{ marginBottom: '1em'}}>{text.adviceTextForPlayers_P1}</h6>
                        <h6 key={i} style={{ marginBottom: '1em'}}>{text.adviceTextForPlayers_P2}</h6>
                        <h6 key={i}>{text.adviceTextForPlayers_P3}</h6>
                    </div>
                  )})}                      
                </DoubleColumnParagraph>
                <DoubleColumnParagraph image='M' odd={false} >
                  {texts.map((text, i) => {
                    return (         
                    <div style={{ marginBottom: '2em'}}>
                        <h6 key={i}>{text.adviceTextForPayers}</h6>
                    </div>
                  )})}           
                  <SimpleButton name='join our paid scheme' linktarget='/paidscheme' />             
                </DoubleColumnParagraph>
        </Scrollable>  
        <Scrollable gotoprevious={'#1'} current={'2'} tipup='menu'>
                <div>up</div><div>total DKs: 0</div>
                <div>
                  Hi lets play a game
                </div>
        </Scrollable>
      </Container>
  )
}

export default AdvicePage;