import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import DoubleColumnParagraph from '../DoubleColumnParagraph/DoubleColumnParagraph';
import { Link } from 'react-router-dom';

function Archive(props) {
   return (         
     <DoubleColumnParagraph image={props.date} type='icon'>
         <Accordion>
             <Card className='Accordion'>
                 <Card.Header className='AccordionHeader' >
                    <Accordion.Toggle as={Link} variant="link" eventKey="0">{props.title}</Accordion.Toggle>
                 </Card.Header>    
                 <Accordion.Collapse eventKey="0">
                 <Card.Body>{props.body}</Card.Body>
                </Accordion.Collapse>
             </Card>
         </Accordion>
     </DoubleColumnParagraph>   
  )
}

export default Archive;

