import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import FrontPage from './Containers/Frontpage';
import Briefingspage from './Containers/Briefingspage';
import AdvicePage from './Containers/AdvicePage';
import Partnerpage from './Containers/Partnerpage';
import Scoreboardpage from './Containers/Scoreboardpage';
import Startupspage from './Containers/Startupspage';

function Router() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

	return (
		<Switch>
		  <Route path="/" exact component={(props) => <FrontPage {...props} ModalEventHandler={handleShow} /> } />			     				
		  <Route path="/briefings" exact component={(props) => <Briefingspage {...props} ModalEventHandler={handleShow} /> } />			     				
		  <Route path="/advice" exact component={(props) => <AdvicePage {...props} ModalEventHandler={handleShow} /> } />			     				
		  <Route path="/partners" exact component={(props) => <Partnerpage {...props} ModalEventHandler={handleShow} /> } />			     				
		  <Route path="/scoreboard" exact component={(props) => <Scoreboardpage {...props} ModalEventHandler={handleShow} /> } />			     				
		  <Route path="/startups" exact component={(props) => <Startupspage {...props} ModalEventHandler={handleShow} /> } />			     				
		</Switch>
        /* <GenericModal show={show} handleClose={handleClose}/> */
    )
	}

export default Router;