import React from 'react';
import '../Components.css';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Arrows(props) {
    return (
        <Col className="scrollableContainer">
        <OverlayTrigger overlay={(<Tooltip id="hi" style={{ color: 'blue'}}>{props.helpText}</Tooltip>)} placement="right">
           <a className="scrollables" 
                href={`${props.goto}`}>{props.direction}</a>
        </OverlayTrigger>
        </Col>
    );
  }
  
 export default Arrows;
