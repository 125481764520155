import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Pages from './Router';
import './App.css';
import ReactGA from 'react-ga';
import './bootstrap_custom.css';
ReactGA.initialize('UA-126360594-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {

  document.body.classList.add('stop-scrolling')
  
  return ( 
    <BrowserRouter >   
      <Pages/>    
    </BrowserRouter >
  );
}

export default App;