import React from 'react';
import Col from 'react-bootstrap/Col';

export const SubscribeForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value
      });

    return (
      <div>
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <input
          ref={node => (email = node)}
          type="email"
          style={{ border: 'none', width: '100%', padding: '0.5em'}}
          placeholder="Your email"
        />
        <br />
        <Col md={2} className="simpleButton" onClick={submit}>
          Submit
        </Col>
      </div>
    );
  };
  